import { appEnv } from 'lib/env'
import { getVerityKeyValues } from './ads'
import { isServer, getDeviceType } from './server'
import { getStoryCategory, parseVideoDuration } from './stories'
import type { Story, StoryVideoSource } from 'api/types'
// @ts-ignore
import promisify from '@gar/promisify'
import { isNull, isUndefined } from 'lodash'
import { formatEidsFunctionData } from 'hooks/hashtag-labs'

// take params from config, merge them with defaults, apply them all to ima base url
// construct and set `cust_params`
// construct and set dynamic values based on video/story

const videoAdProfile = Object.freeze({
  default: 'Web_Default',
  ios: 'Web_iOS'
})

const defaultImaAdSrc = 'https://pubads.g.doubleclick.net/gampad/ads'

const adTagDefaultParams = {
  iu: '/21857335772/Barstool-Sports-Web/Barstool-Original_Video-CSAI',
  sz: '400x300|640x480',
  tcfd: '0',
  npa: '0',
  impl: 's',
  output: 'xml_vmap1',
  unviewed_position_start: '1',
  ad_rule: '1',
  cmsid: appEnv === 'production' ? '2695157' : '2695466',
  vconp: '2',
  vpmute: '0',
  wta: '1',
  ad_type: 'audio_video',
  env: 'instream',
  gdfp_req: '1',
  vpi: '1'
}
// TODO: find appropriate place to set gdpr_consent, UserAgent, and sid

interface AdTagProps {
  ip: string
  ppid: string
  videoSource: StoryVideoSource
  story: Story
  playback: string
  muted?: boolean
  params?: Record<any, any>
  publicaParams?: string
  adBaseUrl?: string
  adTarget?: string
  inArticle?: boolean
  adStory?: Story
}

export async function createVideoAdTagUrl({
  ip,
  ppid,
  story,
  videoSource,
  playback,
  muted = false,
  params = {},
  adBaseUrl = defaultImaAdSrc,
  adTarget,
  inArticle = false,
  adStory,
  publicaParams
}: AdTagProps) {
  const url = new URL(adBaseUrl)

  // Construct the video/story specific top level params
  const videoParams: Record<any, any> = {
    correlator: Math.floor(Math.random() * (1e10 - 1e9) + 1e9), // 10 digit random positive number
    description_url: story.urls.barstoolsports,
    url: story.urls.barstoolsports,
    vid: videoSource.id,
    vpmute: muted ? '1' : '0',
    pp: getDeviceType() === 'ios' ? videoAdProfile.ios : videoAdProfile.default,
    ppid
  }

  if (!isServer) videoParams.UserAgent = navigator.userAgent
  if (['auto', 'click'].includes(playback)) videoParams.vpa = playback
  if (inArticle) {
    videoParams.plcmt = 2
    videoParams.iu = '/21857335772/Barstool-Sports-Web/In-Article_Video-CSAI'
  }
  if (adStory) {
    videoParams.description_url = adStory?.urls.barstoolsports
  }

  const verityKeyValues = getVerityKeyValues(story)
  const htlTargeting = await getHTLVideoParams(ppid, inArticle)

  const getAmazonVideoParamsPromise = promisify(getAmazonVideoParams)
  const amazonResponse = await getAmazonVideoParamsPromise(story, verityKeyValues)

  // Construct the nested URI query string for cust_params set on the ad url
  const encodedCustParamsList = [] // ['author=Big%20Cat', 'authorId=123152134']

  const custStoryParamsPairs = mergeStoryValues(adStory ? [story, adStory] : [story])

  const custParamsPairs = [
    { key: 'videoTags', value: `${videoSource?.media_tags?.join(',')}` },
    { key: 'duration', value: parseVideoDuration(videoSource.duration || 0) },
    { key: 'ip', value: ip || '' },
    { key: 'user-agent', value: navigator.userAgent || '' },
    { key: 'postId', value: story?.id },
    ...custStoryParamsPairs
  ]

  if (adTarget) {
    custParamsPairs.push({ key: 'adtar', value: adTarget })
  }

  if (htlTargeting) {
    const htlParams = Object.keys(htlTargeting)

    for (const key of htlParams) {
      custParamsPairs.push({ key, value: htlTargeting[key] })
    }
  }

  for (const param of custParamsPairs) {
    const { key, value } = param
    encodedCustParamsList.push(`${key}=${encodeURIComponent(value)}`)
  }

  // append amazon params if they exist
  const cust_params = amazonResponse?.qsParams
    ? `${encodedCustParamsList.join('&')}${amazonResponse.qsParams}`
    : encodedCustParamsList.join('&')

  // Merge/finalize video ad params with defaults, config params, and video/story params
  const mergedParams = {
    ...adTagDefaultParams,
    ...params,
    ...videoParams,
    cust_params: publicaParams ? cust_params + '&' + publicaParams : cust_params
  }

  for (const [key, value] of Object.entries(mergedParams)) {
    url.searchParams.set(key, String(value))
  }

  const adUrl = url.href
  return adUrl
}

function getHTLVideoParams(ppid: string, inArticle?: boolean): Promise<{ [key: string]: string }> {
  return new Promise((resolve, reject) => {
    window.pbjs.que.push(async () => {
      const slotName = `/21857335772/Barstool-Sports-Web/${
        inArticle ? 'In-Article_Video-CSAI' : 'Barstool-Original_Video-CSAI'
      }`
      const videoAdUnit: any = {
        code: 'video1',
        ortb2Imp: {
          ext: {
            gpid: slotName,
            data: {
              pbadslot: slotName
            }
          }
        },
        mediaTypes: {
          video: {
            context: 'instream',
            playerSize: [[400, 300]],
            mimes: ['video/mp4', 'application/javascript'],
            protocols: [1, 2, 3, 4, 5, 6, 7, 8],
            playbackmethod: [inArticle ? 2 : 3],
            maxduration: inArticle ? 30 : 15,
            skip: 1,
            pos: 1,
            placement: 1,
            plcmt: inArticle ? 2 : 1,
            api: [2],
            linearity: 1
          }
        }
      }

      try {
        videoAdUnit.bids = window.htlbid.config.prebidGroups['video-instream']
        window.pbjs.addAdUnits(videoAdUnit)

        window.pbjs.setConfig({
          cache: {
            url: 'https://prebid.adnxs.com/pbc/v1/cache'
          },
          userSync: {
            userIds: [
              {
                name: 'pubProvidedId',
                params: {
                  eidsFunction: formatEidsFunctionData(ppid)()
                }
              }
            ]
          }
        })

        const params = await window.pbjs.requestBids()

        const bids = params?.bids?.video1?.bids

        if (bids?.length) {
          const targetingValues = bids.reduce(
            (totalBids: { [key: string]: any }, { adapterCode, adserverTargeting }: { [key: string]: any }) => {
              let _targetingValues: { [key: string]: any } = {}
              for (const [key, value] of Object.entries(adserverTargeting)) {
                _targetingValues[`${key}_${adapterCode}`] = value
              }

              return { ...totalBids, ..._targetingValues }
            },
            {}
          )
          resolve(targetingValues)
        } else {
          resolve({})
        }
      } catch (e) {
        console.log('htl prebid err', e)
        reject()
      }
    })
  })
}

function getAmazonVideoParams(story: Story, verity: Record<any, any>, callback: (res: any, ok?: string) => void) {
  if (isServer) return null
  if (!window.apstag) return null

  const slot = {
    slotID: 'Instream_Web',
    mediaType: 'video',
    slotParams: {
      brandId: story?.brand_id,
      authorId: story?.author?.id,
      tags: story?.tags?.join(','),
      ...verity
    }
  }
  const bidConfig = {
    slots: [slot]
  }

  function processResponse(res: any) {
    return callback(null, res?.[0])
  }

  window.apstag.fetchBids(bidConfig, processResponse)
}

function mergeStoryValues(stories: Story[]): { key: string; value: any }[] {
  if (!stories?.length) {
    return []
  } else {
    const verityValues = stories.map((story) => getVerityKeyValues(story))

    const verityThreats = stories.reduce((threats: [] | { key: string; value: any }[], story: Story) => {
      const storyThreats =
        story?.verity?.threats?.reduce(
          (_storyThreats: [] | { key: string; value: any }[], { id, confidence, risk }) => {
            return [
              ..._storyThreats,
              { key: `verity_${id}_conf`, value: confidence },
              { key: `verity_${id}_risk`, value: risk }
            ]
          },
          []
        ) ?? []
      return [...threats, ...storyThreats]
    }, [])

    return [
      { key: 'authorId', value: combineFields(stories, (story: Story) => story?.author?.id) },
      { key: 'category', value: combineFields(stories, (story: Story) => getStoryCategory(story)) },
      { key: 'tags', value: combineFields(stories, (tags, story: Story) => [...tags, ...story?.tags], true) },
      { key: 'brandId', value: combineFields(stories, (story: Story) => story?.brand_id || '') },
      { key: 'campaign', value: combineFields(stories, (story: Story) => (story?.nsfw ? 'NSFW' : 'default')) },
      {
        key: 'verity_keywords',
        value: combineFields(verityValues, (keywords, value) => [...keywords, ...(value?.verity_keywords ?? [])], true)
      },
      { key: 'verity_iabv1', value: combineFields(verityValues, (value) => value.verity_iabv1 || '') },
      { key: 'verity_iabv2', value: combineFields(verityValues, (value) => value.verity_iabv2 || '') },
      { key: 'verity_neutral', value: combineFields(verityValues, (value) => value.verity_neutral || 0) },
      {
        key: 'verity_positive',
        value: combineFields(verityValues, (value) => value.verity_positive || 0)
      },
      { key: 'verity_negative', value: combineFields(verityValues, (value) => value.verity_negative || 0) },
      ...verityThreats
    ]
  }
}

function combineFields(objects: any[], fieldCheck: (...args: any[]) => any, reduce = false) {
  return (reduce ? objects.reduce(fieldCheck, []) : objects.map(fieldCheck)).filter(
    (value: any) => !(isUndefined(value) || isNull(value) || value?.length === 0)
  )
}
