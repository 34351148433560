import React from 'react'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { applyFastlyParams } from 'utils'
import { truncateHtmlString } from 'utils'
import parseISO from 'date-fns/parseISO'
import isBefore from 'date-fns/isBefore'
import { TaboolaScript } from 'lib/taboola'
import { appEnv } from 'lib/env'

export const defaultDescription =
  'Barstool Sports is a sports & pop culture blog covering the latest news and viral highlights of each and everyday with blogs, videos and podcasts.  By the common man, for the common man.'
export const defaultShareImage = 'https://chumley.barstoolsports.com/union/2021/02/10/adsite-social-share.d89533ed.png'

function formatShareImage(url) {
  if (!url.includes('.gif')) {
    return url
  }
  // render the first frame of gifs in og share card
  return applyFastlyParams(url, { frame: 1 })
}

const Head = ({
  noIndexNoFollow = false,
  noAutoZoom = false,
  story = null,
  videoSource = null,
  viralPost = null,
  playlist = null,
  canonical = null,
  twitterHandler = null,
  children = null,
  taboolaLoaderType = 'homepage',
  ...props
}) => {
  const router = useRouter()
  const pathname = router.asPath.split('?')[0]
  const url = canonical ?? `${process.env.SITE_URL}${pathname}`
  const truncatedStoryHtml = truncateHtmlString(story?.post_type_meta?.standard_post?.raw_content ?? '', 200)

  let headContent = (
    <>
      <title>{props.title || 'Barstool Sports'}</title>
      <meta name='description' content={props.description || defaultDescription} />
      <link rel='canonical' href={url} />
      <meta property='og:url' content={url} />
      <meta property='og:title' content={props.title || ''} />
      <meta property='og:description' content={props.description || defaultDescription} />
      <meta property='og:image' content={props.ogImage || defaultShareImage} />
      <meta property='og:image:width' content={props.ogImageWidth || 1200} />
      <meta property='og:image:height' content={props.ogImageHeight || 630} />
      <meta property='og:type' content={props.ogType || 'website'} />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={props.title || ''} />
      <meta name='twitter:description' content={props.description || defaultDescription} />
      <meta name='twitter:site' content={twitterHandler || '@barstoolsports'} />
      <meta name='twitter:image' content={props.ogImage || defaultShareImage} />
    </>
  )
  if (story) {
    const isFundraiser = story.type === 'fundraiser'
    const title = isFundraiser ? `${story.title} | Donation | Barstool Sports` : `${story.title} | Barstool Sports`
    const publishedDate = parseISO(story.date)
    const isBeforePivot = isBefore(publishedDate, new Date(2020, 5, 18))
    const shouldRenderAmpTag = !isFundraiser && isBeforePivot
    headContent = (
      <>
        <title>{title}</title>
        {shouldRenderAmpTag && (
          <link rel='amphtml' href={story.urls.barstoolsports.replace('https://www.', 'https://amp.')} />
        )}
        <meta name='description' content={truncatedStoryHtml} />
        <link rel='canonical' href={story.urls.canonical} />
        <meta name='date' content={story.date} />
        <meta name='title' content={story.title} />
        <meta name='author' content={story.author.name} />
        <meta name='date' content={story.date} />
        <meta name='news_keywords' content={story.tags.join(', ')} />
        <meta property='article:publisher' content='barstoolsports' />

        <meta property='og:url' content={story.urls.canonical} />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={story.title} />
        <meta property='og:description' content={truncatedStoryHtml} />
        <meta property='og:image' content={`${story.thumbnail.raw}`} />

        {story.author.twitter_handle && <meta name='twitter:creator' content={`@${story.author.twitter_handle}`} />}
        <meta name='twitter:title' content={story.title} />
        <meta name='twitter:description' content={truncatedStoryHtml} />
        <meta name='twitter:image' content={`${formatShareImage(story.thumbnail.raw)}`} />
        <meta name='twitter:image:alt' content={story.title} />
      </>
    )
  } else if (viralPost) {
    const mediaType = viralPost.media?.type ?? null
    const thumbnail = viralPost.media?.thumbnail ?? null
    const attribution = viralPost.attribution
    const postUrl = `${process.env.SITE_URL}/viral-posts/${viralPost.id}`
    const attributionLabel = attribution.twitter || attribution.instagram || 'barstoolsports'
    const message = viralPost.message ?? ''
    headContent = (
      <>
        <title>Barstool Sports Viral | Barstool Sports</title>
        <link rel='canonical' href={url} />
        {mediaType === 'video' && <meta property='og:video' content={viralPost.media.src} />}
        <meta property='og:title' content={`Viral Post by ${attributionLabel}`} />
        {mediaType === 'video' ? (
          <meta property='og:type' content='video.other' />
        ) : (
          <meta property='og:type' content='article' />
        )}
        <meta property='og:image' content={thumbnail} />
        {mediaType === 'image' && <meta property='og:image' content={viralPost.media.src} />}
        <meta property='og:url' content={postUrl} />
        <meta property='og:description' content={message} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@barstoolsports' />
        <meta name='twitter:creator' content='@barstoolsports' />
        <meta name='twitter:title' content={`Viral Post by ${attributionLabel}`} />
        <meta name='twitter:description' content={message} />
        <meta name='twitter:image' content={thumbnail} />
        <meta name='twitter:image:alt' content={message} />
        <meta name='twitter:app:name:iphone' content='Barstool' />
        <meta name='twitter:app:id:iphone' content='456805313' />
        <meta name='twitter:app:name:ipad' content='Barstool' />
        <meta name='twitter:app:id:ipad' content='456805313' />
      </>
    )
  } else if (playlist) {
    const image = playlist.thumbnail.location + playlist.thumbnail.large
    headContent = (
      <>
        <title>{`${playlist.title} - Barstool Sports`}</title>
        <link rel='canonical' href={url} />
        <meta property='og:title' content={playlist.title} />
        <meta property='og:image' content={image} />
        <meta property='og:type' content='article' />
        <meta property='og:url' content={props.canonical} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@barstoolsports' />
        <meta name='twitter:title' content={playlist.title} />
        <meta name='twitter:image' content={image} />
        <meta name='twitter:image:alt' content={playlist.title} />
        <meta name='twitter:app:name:iphone' content='Barstool' />
        <meta name='twitter:app:id:iphone' content='456805313' />
        <meta name='twitter:app:url:iphone' content={`barstoolsports://playlists/${playlist.id}`} />
        <meta name='twitter:app:name:ipad' content='Barstool' />
        <meta name='twitter:app:id:ipad' content='456805313' />
        <meta name='twitter:app:url:ipad' content={`barstoolsports://playlists/${playlist.id}`} />
      </>
    )
  }

  const hlsVideoSource = videoSource?.sources.find(({ src, type }) => {
    return ['application/vnd.apple.mpegurl', 'application/x-mpegURL'].includes(type) && src.startsWith('https')
  })

  const videoHeadContent = hlsVideoSource && (
    <>
      <meta key='og:video' property='og:video' content={hlsVideoSource.src} />
      <meta key='og:video:type' property='og:video:type' content={hlsVideoSource.type} />
    </>
  )

  return (
    <NextHead>
      <meta charSet='UTF-8' />
      <meta name='viewport' content={`width=device-width, initial-scale=1${noAutoZoom ? ', maximum-scale=1' : ''}`} />
      <meta property='fb:app_id' content={process.env.FB_APP_ID} />
      <meta property='fb:pages' content={process.env.FB_PAGES_ID} />
      <link rel='icon' href='/favicon.ico' />

      <link rel='preconnect' href='https://union.barstoolsports.com'></link>
      <link rel='preconnect' href='https://chumley.barstoolsports.com'></link>

      <meta name='twitter:app:name:iphone' content='Barstool' />
      <meta name='twitter:app:id:iphone' content='456805313' />
      <meta name='twitter:app:name:ipad' content='Barstool' />
      <meta name='twitter:app:id:ipad' content='456805313' />
      <meta name='twitter:site' content='@barstoolsports' />
      <meta name='twitter:card' content='summary_large_image' />

      {/* Page specific head content */}
      {headContent}

      {/* Video specific head content */}
      {videoHeadContent}

      {/* Disable crawlers */}
      {(noIndexNoFollow || process.env.SEO_CRAWLING_DISABLED === 'true') && (
        <meta name='robots' content='noindex,nofollow' />
      )}
      {appEnv !== 'production' && <meta name='googlebot' content='noindex,nofollow' />}

      <TaboolaScript type={taboolaLoaderType} />

      {/* Render children */}
      {children}
    </NextHead>
  )
}

export default Head
