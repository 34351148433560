import { observable, flow } from 'mobx'
import storage from 'lib/storage'
import { authApi } from 'api'
import { CommonStore } from 'stores/common'
import { setCookie } from 'lib/cookies'

export const AuthStore = () => {
  const signup = flow(function* (email, password) {
    const response = yield this.api.signup({ email, password })
    storage.authToken = response.token
    this.loggedIn = true
    if (response?.user?.id) {
      setCookie('ajs_user_id', response.user.id, 365)
    }
    return response
  })
  const login = flow(function* (email, password) {
    const response = yield this.api.login({ email, password })
    storage.authToken = response.token
    this.loggedIn = true
    if (response?.user?.id) {
      setCookie('ajs_user_id', response.user.id, 365)
    }
    return response
  })
  const logout = function () {
    storage.authToken = null
    this.loggedIn = false
  }
  const forgotPassword = flow(function* (email) {
    const response = yield this.api.forgotPassword({ email })
    return response
  })
  const resetPassword = flow(function* (token, password) {
    const response = yield this.api.resetPassword({ token, password })
    storage.authToken = response.token
    this.loggedIn = true
    return response
  })
  const spotimAuthorize = flow(function* (codeA) {
    if (!storage.authToken) return
    const response = yield this.api.spotimAuthorize(storage.authToken, codeA)
    return response
  })

  return observable({
    ...CommonStore,
    api: authApi,
    email: null,
    signup,
    login,
    logout,
    forgotPassword,
    resetPassword,
    spotimAuthorize,
    loggedIn: !!storage.authToken,
    get userId() {
      if (!this.loggedIn) return null
      if (storage.userId) {
        setCookie('ajs_user_id', storage.userId, 365)
      }
      return storage.userId
    },
    get userEmail() {
      if (!storage.user) return null
      return storage.user.email
    }
  })
}

AuthStore.cacheKey = 'AuthStore'

export default AuthStore
